<template>
  <div class="relative bg-indigo-800">
      <div class="absolute inset-0">
          <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
      </div>
      <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
          <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Privacy Policy of IAAW Limited Trading as Rapid Sale</h1>
          <p class="mt-6 max-w-3xl text-xl text-indigo-100">Introduction: IAAW Limited, trading as Rapid Sale, is committed to protecting your privacy and ensuring the proper handling of personal data. This privacy notice details how we process personal data through interactions on our website, including data provided for cash offers, contact forms, or competitions. Our website is not intended for children and does not knowingly collect data relating to children.</p>

          <h2 class="text-2xl text-white mt-4">Consent and Information Collection</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">We collect your name, email address, telephone number, and property details for processing enquiries and transactions. Personal data is used to communicate with you and provide our services effectively.</p>

          <h2 class="text-2xl text-white mt-4">Types of Personal Data Collected</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">Identity Data: Names, marital status, titles, dates of birth, and genders. Contact Data: Postal addresses, email addresses, and phone numbers. Profile Data: Orders, interests, preferences, feedback, and survey responses. Technical Data: Browser types, operating systems, and other technology-related information. Usage Data: Information on how you use our website. Aggregated Data: Non-personally identifiable statistical or demographic information.</p>

          <h2 class="text-2xl text-white mt-4">Data Usage</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">Data is used for contract performance, legitimate interests, and legal compliance. Personal data may be shared with third parties like solicitors or estate agents as necessary.</p>

          <h2 class="text-2xl text-white mt-4">Data Retention</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">Data is retained for the duration of your engagement with our services plus six years, unless legal or regulatory requirements dictate otherwise.</p>

          <h2 class="text-2xl text-white mt-4">Your Rights</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">Rights to access, correct, or erase your personal data, and to be informed about data usage. Contact for data-related requests: DPO@rapidsale.co.uk. Address: IAAW Limited / Rapid Sale, Bond Street, St. Helier, JE2 3NP.</p>

          <h2 class="text-2xl text-white mt-4">Changes to Personal Details</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">Notify us of any changes to your personal details to ensure accurate records.</p>

          <h2 class="text-2xl text-white mt-4">Cookies Policy</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">We use cookies for site analytics, performance, and social media integration. For detailed guidance on cookies, please visit [Link to detailed cookie guidance].</p>

          <h2 class="text-2xl text-white mt-4">Data Security</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">We implement security measures including firewalls and confidentiality protocols. Telephone calls may be recorded for quality assurance.</p>

          <h2 class="text-2xl text-white mt-4">Data Breaches and Complaints</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">Report any data misuse or security concerns to our Data Protection Officer at DPO@rapidsale.co.uk. For unresolved issues, contact the ICO at 0303 123 1113.</p>

          <h2 class="text-2xl text-white mt-4">Company Details</h2>
          <p class="mt-4 max-w-3xl text-xl text-indigo-100">IAAW Limited, registered in Jersey (Registration number: 121664, ICO: ZB523327). Contact Number: 0330 022 8803.</p>

      </div>
  </div>
</template>

      
      <script>
    
      
      export default {
          name: 'PrivacyPolicy',
          metaInfo() {
            return {
              title: 'Privacy Policy | Rapid Sale',
              meta: [
                {
                  name: 'description',
                  content: 'Privacy Policy for Rapid Sale',
                },
                {
                  name: 'keywords',
                  content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
                },
              ],
              link: [
                { rel: 'canonical', href: 'https://rapid-sale.co.uk/privacy-policy' }
              ]
            };
          },
        };
      </script>
      
    
      