
<template>
  <div class="relative" style="height: auto;">
    <AppHeader />
    <router-view></router-view>
    <div
      v-if="$route.path === '/'"
      class="header-container flex flex-col items-center bg-cover bg-center relative"
      :style="`background-image: url(${headerImageUrl})`"
    >
    <div class="overlay absolute inset-0 bg-blue-900 opacity-90"></div>      
      <div class="flex flex-col items-center z-10" :class="{ 'hidden': showQuiz || showContact }">
        <div class="key-points-container w-full px-4 sm:w-1/2 sm:px-0 text-left sm:text-center pt-0 sm:pt-20 md:pt-10 px-5 sm:px-0">
          <h1 class="text-xl md:text-3xl font-extrabold text-white text-center pt-7 sm:text-center px-2 tracking-wider">We Buy Property for Cash Fast, Regardless of Circumstances - 100% No Fees, Sell Fast in 7 Days</h1>
          <ul class="mt-4 flex space-x-0 sm:space-x-4 md:space-x-6 justify-start sm:justify-center flex-wrap text-left text-white">
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Quick sale process
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Get Cash in 7 days or sooner
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Best prices paid
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> No viewings or sale boards
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> Up to 3 months rent-free stay
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> No last minute price drops
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> We use our own company funds
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> We buy regardless of condition
            </li>
            <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
              <i class="fas fa-check text-green-500 mr-2"></i> A guaranteed sale
            </li>
          </ul>
          
        </div><AddressForm @addressSelected="onAddressSelected" class="w-full px-4 sm:w-1/2 sm:px-0 mt-4" />


        <div class="mt-4">
        <div class="w-full sm:max-w-sm mx-auto text-sm text-gray-300 pb-10 text-center">
          <p class="text-xs font-bold mb-2">
            <span class="text-red-500 mr-1">&#9888;</span>
            Our offers are competitive, and there's no obligation to accept. You have nothing to lose.
          </p>
        </div>
      </div>


      </div>

      <div class="flex flex-col items-center pt-10 mx-4 justify-start z-10" :class="{ 'hidden': !showQuiz }">
        <div class="text-xl sm:text-3xl font-bold text-white mb-2">
            Tell us about your property...
        </div>
        <div class="text-md sm:text-lg text-white mb-8">
            It only takes a few seconds
      </div>


        <QuizForm @completed="onQuizComplete" />
        
        <div class="sm:max-w-sm mx-auto text-sm text-white pt-5 pb-10" style="font-size: 10px;">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6 inline-block mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
          </svg>
          This information helps us tailor our offer to meet your specific needs.
      </div>
      </div>

      <div class="flex flex-col items-center pt-10 justify-start z-10 pb-10" :class="{ 'hidden': !showContact }">
        <ContactForm :address-data="addressData" 
                      :quiz-data="quizData" 
                      :gclid="gclid" 
                      :utmCampaign="utmCampaign" 
                      :utmSource="utmSource" 
                      :utmMedium="utmMedium"
                      :utmKeyword="utmKeyword"
                      :utmMatch="utmMatch" 
                      :utmDevice="utmDevice" />
      </div>
    </div>



    <!-- Review Widget Container -->
    <div class="review-widget-container bg-gray-100 py-7" v-if="!$route.path.startsWith('/landerv1/') && !$route.path.startsWith('/financial/') && !$route.path.startsWith('/landerv2/')">
      <!-- <div class="review-cards-container bg-gray-100 py-4"> -->
      <div class="max-w-4xl mx-auto">
        <h2 class="text-4xl font-semibold text-blue-600 mb-6 text-center">Our Reviews</h2>
        <p class="text-center text-lg text-gray-700 mb-8">We have over 135 - 5 Star Reviews from our trusted 3rd party review collector.</p>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <div v-for="review in reviews" :key="review.id" class="review-card bg-white p-4 rounded-lg shadow-lg">
            <div class="flex justify-center mb-4">
              <img src="@/assets/reviewsio-logo.png" alt="Reviews.io Logo" class="logo">
            </div>
            <div class="flex items-center mb-2">
              <div class="text-lg font-bold">{{ review.name }}</div>
            </div>
            <div class="flex items-center mb-2">
              <div class="text-yellow-500">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
              </div>
            </div>
            <div class="text-gray-700">{{ review.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!---</div>-->

  <div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/landerV1/') && !$route.path.startsWith('/landerV2/') && !$route.path.startsWith('/thank-you')" class="guaranteed-offer p-6">
  <h2 class="text-3xl font-semibold text-blue-600 mb-6 text-center">Want a Guaranteed Offer?</h2>
  <p class="text-lg px-4 mb-6 text-gray-700">
    With no fees, complete flexibility on your moving date, 100% confidentiality, no stress, and no estate agents! We provide speed, certainty, simplicity.
  </p>
  <div class="offer-steps grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
    <div class="step">
      <i class="fas fa-comments chat-bubble-icon text-3xl text-green-500"></i>
      <p class="mt-2">Tell us about your property</p>
    </div>
    <div class="step">
      <i class="fas fa-handshake cash-offer-icon text-3xl text-green-500"></i>
      <p class="mt-2">Accept our cash offer</p>
    </div>
    <div class="step">
      <i class="fas fa-university bank-icon text-3xl text-green-500"></i>
      <p class="mt-2">Cash in the bank in 7 days</p>
    </div>
  </div>
  <p class="text-lg px-4 mt-6 mb-8 text-gray-700">
    Our service is simple, stress-free, giving you peace of mind.
  </p>
  <button class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg text-xl mx-auto block transition duration-300 ease-in-out" @click="goToTop">
    Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
  </button>
</div>


<div v-if="!($route.path.startsWith('/landerV1/') || $route.path.startsWith('/landerV2/') || $route.path.startsWith('/articles/') || $route.path.startsWith('/thank-you'))" class="bg-blue-900 text-white p-6 rounded-lg shadow-lg">
  <div class="max-w-4xl mx-auto text-center">
    <h2 class="text-3xl font-bold mb-6">The Rapid Sale Service - Rapid Cash Property Sales</h2>
    <p class="mb-6">Sell your house fast for cash with no fees, no waiting, and no hassle.</p>
    
    <ul class="list-disc list-inside mb-6 text-left">
      <li>Immediate cash payment directly to your bank.</li>
      <li>No delays, no endless viewings.</li>
      <li>No last-minute price drops.</li>
      <li>100% No fees to pay.</li>
      <li>Expert property advisors.</li>
      <li>Specialists in a range of circumstances.</li>
    </ul>
    
    <p class="mb-6">We're genuine cash buyers with funds ready, ensuring a swift and secure transaction. Check our reviews and experience the Rapid Sale difference.</p>

     <!-- Image of the office with staff -->
     <div class="mb-6">
      <img src="@/assets/rapid-staff-image.jpg" alt="Rapid Sale Office Staff" class="mx-auto rounded-lg shadow-lg">
    </div>
    
    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg mt-6 text-xl" @click="goToTop">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
    
    <p class="text-sm mt-6 italic">Beware of brokers posing as cash buyers. Always verify the authenticity and read genuine reviews.</p>
  </div>
</div>





<!-- Comparison -->
<div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/thank-you')" class="bg-gray-200 py-10">
  <div class="bg-white p-6 rounded-lg shadow-lg text-center max-w-4xl mx-auto">
    <h2 class="text-3xl font-bold mb-6 text-blue-800">We Can Help Under Any Circumstances</h2>
    <p class="mb-6 text-gray-700">Over the years, we've encountered and successfully dealt with a multitude of situations. Whether it's debt, mortgage arrears, repossession, bereavement, matrimonial issues, or any other challenge, we're here to offer swift and effective solutions.</p>

    <div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
      <!-- Cards for each category -->
      <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <p class="font-semibold">Matrimonial</p>
      </div>

      <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <p class="font-semibold">Facing Repossession</p>
      </div>


      <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <p class="font-semibold">Mortgage Arrears</p>
      </div>


      <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <p class="font-semibold">Retirement</p>
      </div>


      <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <p class="font-semibold">Inheritance</p>
      </div>


      <div class="flex flex-col items-center p-4 bg-blue-100 rounded-lg">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-500 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <p class="font-semibold">And more...</p>
      </div>

      <!-- Repeat for other categories... -->
    </div>
    <p class="mb-2 text-gray-700 pt-4">No matter the complexity of your situation, we're prepared to provide the support and solutions you need.</p>

    <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg mt-2 text-xl" @click="goToTop">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
  </div>
</div>




<!-- FAQs Section -->
<div id="app" class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12" v-if="!$route.path.startsWith('/articles/') && $route.path !== '/thank-you'" >
  <div class="text-center">
    <h2 class="text-3xl font-bold text-gray-900">FAQs</h2>
  </div>
  <div class="mt-8">
    <dl class="space-y-6">
      <div v-for="(item, index) in faqs" :key="index" class="space-y-2">
        <dt class="cursor-pointer px-4 py-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors duration-200" @click="toggle(index)" :class="{'text-gray-900': item.isOpen, 'text-gray-700': !item.isOpen}">
          <div class="flex justify-between items-center">
            {{ item.question }}
            <svg :class="{'rotate-180': item.isOpen}" class="inline-block w-4 h-4 transition-transform duration-200" viewBox="0 0 24 24">
              <path d="M19 9l-7 7-7-7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
        </dt>
        <dd v-show="item.isOpen" class="text-gray-600 px-4 py-2 bg-gray-100">
          {{ item.answer }}
        </dd>
      </div>
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="goToTop">
        Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
    </dl>
  </div>
</div>


<div v-if="!$route.path.startsWith('/articles/')" class="bg-gray-200 py-8">
  <div class="max-w-3xl mx-auto">
    <h2 class="text-2xl font-bold mb-4 text-center">We have advertised in:</h2>
    <div class="flex flex-wrap justify-center items-center">
      <div class="mx-4 my-2">
        <img src="@/assets/logo1.png" alt="Logo 1" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo2.png" alt="Logo 2" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo3.png" alt="Logo 3" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo4.png" alt="Logo 4" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo5.png" alt="Logo 5" class="h-12">
      </div>
      <div class="mx-4 my-2">
        <img src="@/assets/logo6.png" alt="Logo 6" class="h-12">
      </div>
    </div>
  </div>
</div>

<!---
<div v-if="!$route.path.startsWith('/articles/') && !$route.path.startsWith('/thank-you')" class="mx-auto p-4 max-w-3xl">
  <ArticleList />
</div> -->


<footer class="bg-gray-900" aria-labelledby="footer-heading">
  <h2 id="footer-heading" class="sr-only">Footer</h2>
  <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
    <div class="xl:grid xl:grid-cols-3 xl:gap-8">
      <div class="space-y-8">
        <img class="h-7" src="@/assets/rapid-logo-white.svg?color=indigo&shade=500" alt="Company name">
        <p class="text-sm leading-6 text-gray-300">As seasoned quick sale specialists, we offer a hassle-free experience with zero extra costs. Our attractive cash offers are tailored to your convenience. Plus, you can relish up to 3 months of rent-free living following the sale. We understand that each property has unique circumstances. Our team thoroughly evaluates every property based on its individual merit.</p>
        <div class="flex space-x-6">
          <a href="https://www.facebook.com/rapidsaleukofficial/" class="text-gray-500 hover:text-gray-400">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          </a>

          <a href="https://twitter.com/RapidSaleUk" class="text-gray-500 hover:text-gray-400">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
         
          <!-- 
          <a href="#" class="text-gray-500 hover:text-gray-400">
            <span class="sr-only">YouTube</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z" clip-rule="evenodd" />
            </svg>
          </a>-->

        </div>
      </div>
      <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 class="text-sm font-semibold leading-6 text-white">Useful Links</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li>
                <a href="/financial/rapid-sale-stop-repossession" class="text-sm leading-6 text-gray-300 hover:text-white">Stop Repossession</a>
              </li>
              <li>
                <a href="/financial/stop-property-eviction-with-rapid-sale" class="text-sm leading-6 text-gray-300 hover:text-white">Stop Eviction</a>
              </li>
            </ul>
          </div>
          <div class="mt-10 md:mt-0">
            <h3 class="text-sm font-semibold leading-6 text-white">Company</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li>
                <a href="/about-us" class="text-sm leading-6 text-gray-300 hover:text-white">About Us</a>
              </li>
              <li>
                <a href="/how-we-work" class="text-sm leading-6 text-gray-300 hover:text-white">How We Work</a>
              </li>
              <li>
                <a href="/contact-us" class="text-sm leading-6 text-gray-300 hover:text-white">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li>
                <a href="/privacy-policy" class="text-sm leading-6 text-gray-300 hover:text-white">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-of-use" class="text-sm leading-6 text-gray-300 hover:text-white">Terms of Use</a>
              </li>
            </ul>
          </div>
          <div class="mt-10 md:mt-0">
          <!--
            <h3 class="text-sm font-semibold leading-6 text-white">Legal</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li>
                <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Claim</a>
              </li>
              <li>
                <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Privacy</a>
              </li>
              <li>
                <a href="#" class="text-sm leading-6 text-gray-300 hover:text-white">Terms</a>
              </li>
            </ul>-->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
      <p class="text-xs leading-5 text-gray-400">&copy; 2008 - {{ currentYear }} IAAW Limited trading as Rapid Sale. All rights reserved. Registered in Jersey: Registration number: 121664 | ICO: ZB523327.</p>
    </div>
  </div>
</footer>




</template>



<script>
/* global carouselInlineWidget */
import AppHeader from "@/components/Header.vue";
import AddressForm from "./components/AddressForm.vue";
import QuizForm from "./components/QuizForm.vue";

import ContactForm from "./components/ContactForm.vue";
//import CountdownTimer from "./components/CountdownTimer.vue";
//import ArticleList from './components/ArticleList.vue';


export default {
  components: {
    AppHeader,
    AddressForm,
    QuizForm,
    ContactForm,
    //CountdownTimer,
    //ArticleList, // Add this line to register the ArticleList component

  },
  data() {
  return {
    addressData: {
      street: "",
      postcode: "",
    },
    quizData: {},
    showQuiz: false,
    showContact: false,
    address: {},
    utmMatch: '',
    gclid: '',
    utmCampaign: '',
    utmSource: '',
    utmMedium: '',
    utmKeyword: '',
    utmDevice: '',
    headerImageUrl: require("@/assets/rapid-sale-row-houses2.jpg"),
    currentYear: new Date().getFullYear(),
    faqs: [
      {
        question: 'WHY CHOOSE RAPID SALE?',
        answer: 'Thank you for considering us for your property sale. We offer a fast, efficient, and stress-free solution for selling your property. Our professional team operates with discretion and efficiency, ensuring a smooth transaction. We have the financial resources to make immediate decisions and provide you with a quick cash payment, tailored to your timeline. Whether you\'re facing financial challenges, lifestyle changes, or any other circumstances, our experienced team is ready to offer a fair and competitive price for your property. We\'re known for our transparent and straightforward process, ensuring you\'re well-informed every step of the way.Choose us for peace of mind, rapid service, and a fair cash offer.',
        isOpen: false
      },
      {
        question: 'DO WE BUY ALL TYPES OF PROPERTY? ABSOLUTELY!',
        answer: 'Our portfolio includes a diverse range of properties - from flats and houses to detached and semi-detached homes, and even bungalows. Condition is never a barrier; whether it\'s pristine or needs some TLC, we\'re interested. This extends to land, commercial buildings, unique or unconventional properties, and even those that are derelict. We consider every type of property.',
        isOpen: false
      },
      {
        question: 'WILL I HAVE TO PAY ANY FEES?',
        answer: 'No, you won\'t have to pay any fees. Just accept our offer, and that\'s it.',
        isOpen: false
      },
      {
        question: 'I\'M FACING REPOSSESSION OR EVICTION, CAN YOU HELP?',
        answer: 'Absolutely. As cash buyers, we specialise in fast transactions to prevent repossession. We recognise the stress you\'re facing and are committed to providing a solution that is quick, certain, and straightforward. Additionally, we offer flexible move-out options to suit your schedule. Typically, we can allow you to stay in the property for up to three months after purchase, giving you the time and peace of mind you need during this transition.',
        isOpen: false
      },
      {
        question: 'WHAT SETS YOUR SERVICE APART FROM SITES THAT CLAIM TO PURCHASE PROPERTIES FOR 100% MARKET VALUE?',
        answer: 'Unlike services that claim to buy properties at 100% market value, often involving lengthy and uncertain brokerage or assisted sales agreements (ASA), our service stands out for its efficiency, speed, and transparency. We focus on quick, straightforward property purchases, catering to those who genuinely need a rapid sale. We understand the stresses of selling property and aim to make your experience as hassle-free as possible. Our approach is different: we provide a fair, competitive cash offer with no hidden costs, backed by our own cash reserves for immediate decisions and quick completions. While every property is unique and we\'re open to alternative selling methods if they better suit your needs, our primary goal is to offer a tailored, transparent service that aligns with your property selling goals, ensuring a smooth and trustworthy transaction.',
        isOpen: false
      },
    ],
    reviews: [
      { id: 1, name: "Lucinda Fyfield", text: "I cannot fault the help from Adam and his team. Communication has been great from start to finish. In a time that was stressful Adam has alleviated the stress. I would highly recommend using this company and will recommend to those who may need them in the future." },
      { id: 2, name: "David Lisle", text: "Would fully recommend this company. Professional all the way through kept fully informed. Claire was excellent, answered all my calls quickly, reassured me throughout and gave me all the information and guidance I needed to help me through. Absolute first class." },
      { id: 3, name: "Mark Simkins", text: "Wanted to dispose of a house without hassle, Adam delivered! Couple of phone calls and house bought for cash. Sale completed in just over a week, minimal effort required from me!" }
    ]
  };
},

  mounted() {
    this.loadReviewWidgetScript();
    this.loadReviewWidgetStyles();
    // Any other code you want to run when the component mounts
  },

  created() {
    this.parseUrlParameters();
    // Any other code you want to run during component creation
  },

  methods: {
    goToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    onAddressSelected(address) {
      this.addressData = address;
      this.showQuiz = true;
    },

    onQuizComplete(quizResults) {
      this.quizData = quizResults;
      this.showQuiz = false;
      this.showContact = true;
    },

    parseUrlParameters() {
      const urlParams = new URLSearchParams(window.location.search);
      this.utmCampaign = urlParams.get('utm_campaign') || '';
      this.utmSource = urlParams.get('utm_source') || '';
      this.utmMedium = urlParams.get('utm_medium') || '';
      this.utmKeyword = urlParams.get('utm_keyword') || '';
      this.utmDevice = urlParams.get('utm_device') || '';
      this.utmMatch = urlParams.get('utm_matchtype') || '';
      this.adId = urlParams.get('adid') || '';
      this.adSetId = urlParams.get('adsetid') || '';
      this.gclid = urlParams.get('gclid') || '';
    },

    toggle(index) {
      this.faqs[index].isOpen = !this.faqs[index].isOpen;
    },

    loadReviewWidgetScript() {
      // Check if the current route path starts with /articles
      if (this.$route.path.startsWith('/articles')) {
        console.log('Review widget script loading is skipped for this page.');
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://widget.reviews.co.uk/carousel-inline-iframeless/dist.js?_t=2023122013';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        new carouselInlineWidget('reviewsio-carousel-widget', {
          store: 'rapid-sale',
          sku: '',
          lang: 'en',
          carousel_type: 'topHeader',
          styles_carousel: 'CarouselWidget--topHeader',
          options: {
            general: {
              review_type: 'company, product',
              min_reviews: '1',
              max_reviews: '20',
              address_format: 'CITY, COUNTRY',
              enable_auto_scroll: false,
            },
            header: {
              enable_overall_stars: true,
              rating_decimal_places: 2,
            },
              reviews: {
            /*Show customer name*/
            enable_customer_name: true,
            /*Show customer location*/
            enable_customer_location: false,
            /*Show "verified review" badge*/
            enable_verified_badge: true,
            /*Show "verified subscriber" badge*/
            enable_subscriber_badge: true,
            /*Show "I recommend this product" badge (Only for product reviews)*/
            enable_recommends_badge: true,
            /*Show photos attached to reviews*/
            enable_photos: true,
            /*Show videos attached to reviews*/
            enable_videos: true,
            /*Show when review was written*/
            enable_review_date: true,
            /*Hide reviews written by the same customer (This may occur when customer reviews multiple products)*/
            disable_same_customer: true,
            /*Minimum star rating*/
            min_review_percent: 4,
            /*Show 3rd party review source*/
            third_party_source: true,
            /*Hide reviews without comments (still shows if review has a photo)*/
            hide_empty_reviews: true,
            /*Show product name*/
            enable_product_name: true,
            /*Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2)*/
            tags: "",
            /*Show branch, only one input*/
            branch: "",
            enable_branch_name: false,
            enable_auto_scroll: false,
          },
          },
          translations: {
            verified_customer: "Verified Customer",
          },
          styles: {
            '--base-font-size': '16px',
            '--base-maxwidth':'83%',
            // ... more style settings ...
          },
        });
      };
    },

    loadReviewWidgetStyles() {
      const loadStyle = (href) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        document.head.appendChild(link);
      };

      loadStyle('https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2023122013');
      loadStyle('https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2023122013');
    },
  },

  // Other component options...
};
</script>

<style>

.header-container {
  /* Other styles */
  position: relative; /* Add relative positioning */
}

@media screen and (max-width: 640px) {
  .sm\:max-w-sm {
    max-width: 84%;
  }
}

.guaranteed-offer {
  margin: 20px 0;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.guaranteed-offer h2 {
  font-size: 2.5rem; 
  font-weight: bold; 
  margin-bottom: 15px; 
}

.offer-steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex-basis: 30%; 
  box-sizing: border-box; 
}

.chat-bubble-icon,
.cash-offer-icon,
.bank-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .step {
    flex-basis: 100%;
  }
}

.bg-custom-color {
  background-color: blue;
}

@media (max-width: 640px) {
  .small-device-table {
      font-size: 14px;
    }

  .small-device-table td,
  .small-device-table th {
      padding: 4px 8px;
    }
  }

.small-device-table tfoot {
      font-size: 6px;
}

/* Adding hover link styles */
.hover-link {
  transition: color 0.3s, transform 0.3s;
}

.hover-link:hover {
  color: red; /* Changing color on hover */
  transform: scale(1.05); /* Adding a little scaling effect on hover */
}

.R-ReviewsList {
    /* Add your styles here */
    margin: 0 auto;
    max-width: 100px; /* or 100% if you want it full width on smaller screens */
    padding: 15px;
    //background-color: #f8f8f8; /* Example background color */
  }

  /* Styles for individual review items */
  .R-ReviewsList__item {
    background-color: #ffffff; /* Set a white background for the review cards */
    border-radius: 8px;
    margin-bottom: 10px;
    //margin-left:1px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for depth */
    transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
  }

  /* Hover effect for review items */
  .R-ReviewsList__item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  /* Styles for the review author's name */
  .cssVar-authorName {
    font-weight: bold;
    color: #333;
  }

  /* Styles for the star ratings */
  .R-RatingStars__stars {
    color: #ffcc00; /* Gold color for stars */
  }

  /* Styles for the review text */
  .R-ReviewsList__item--body {
    font-size: 1rem;
    line-height: 1.5;
    color: #666; /* Darker text for readability */
    margin-top: 10px;
  }

  /* Styles for the verified badge */
  .R-BadgeElement__icon {
    display: inline-block;
    margin-right: 5px;
    /* add more styles if needed */
  }

  /* Styles for the review date */
  .R-TextBody--xxxxs {
    text-align: right;
    color: #999;
    font-size: 0.75rem;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .R-ReviewsList {
      padding: 10px;
    }

    .R-ReviewsList__item {
      padding: 15px;
    }

    .R-ReviewsList__item--body {
      font-size: 0.9rem;
    }    
  }

  .review-card {
  max-width: 300px;
  margin: 0 auto;
}

/* Mobile-specific adjustments */
@media only screen and (max-width: 600px) {
  .flex.items-center.mb-2.px-2.sm\:px-0.pr-6 {
    padding-right: 4px; /* Reduce right padding */
    margin-bottom: 1px; /* Reduce space between items */
  }

  .fas.fa-check.text-green-500.mr-2 {
    margin-right: 8px; /* Reduce space after the icon */
  }
}


</style>

