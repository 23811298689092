<template>
  <div class="relative">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover" src="@/assets/rapid-sale-row-houses2.jpg" alt="How Rapid Sale Works">
      <div class="absolute inset-0 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <!-- Add the black box styling here -->
      <div class="bg-black bg-opacity-82 rounded-lg p-6">
        

        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl pb-2">How We Work</h1>

        <h2 class="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold tracking-tight text-white">Discover Reliable and Swift Cash Property Transactions</h2>
        
        <p class="mt-6 max-w-3xl text-xl text-white">At the heart of our service is our proficiency in cash property purchases, honed through years of successful transactions. We have a rich history of acquiring, and strategically managing a diverse portfolio of properties. This extensive experience has cemented our status as dependable and seasoned property buyers.</p>

        <p class="mt-6 max-w-3xl text-xl text-white">Opting for our services means choosing a streamlined and stress-free experience. Our process begins with a thorough assessment of your property, leading to a fair and assured cash offer. Once you agree, we'll proceed with drafting a formal agreement via our legal team, guaranteeing a transaction that is not only quick but also transparent and secure.</p>

        <p class="mt-6 max-w-3xl text-xl text-white">We're committed to ensuring your comfort throughout the process. To this end, we take care of your conveyancing fees and offer flexibility in setting the completion date. Need quick access to funds? We can finalise the deal in as little as 7 days. If you need more time, we can adjust our timeline to suit your schedule.</p>

        <p class="mt-6 max-w-3xl text-xl text-white">Trust us for a straightforward, reliable cash property transaction that prioritizes your needs and convenience.</p>
        
        <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
          Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<style>


</style>

<script>
export default {
  name: 'HowWeWork',
  metaInfo() {
    return {
      title: 'How We Work | Rapid Sale ',
      meta: [
        {
          name: 'description',
          content: 'How Rapid Sale Works - Get a Free Cash Offer Today - A Team with 50 years experience.',
        },
        {
          name: 'keywords',
          content: 'property cash offer, we buy any home, fast house sale, cash house buyers',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://rapidsale.co.uk/how-we-work' }
      ]
    };
  },
};
</script>

      
    
      