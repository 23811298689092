<template>
  <div id="quiz-form" class='bg-white shadow-md rounded p-8' ref='quizForm'>
    <div v-if='currentQuestionIndex < questions.length'>
      <div class='flex items-center justify-between mb-1'>
        <div class='text-sm font-bold'>
          Step {{ currentQuestionIndex + 1 }} of {{ questions.length }}
        </div>
      </div>
      <div class='border-b-2 border-gray-200 mb-2'></div>
      <div class='text-xl mb-4 text-blue-500 font-semibold text-center px-2'>
        {{ questions[currentQuestionIndex].question }}
      </div>
      <select
        v-model='selectedAnswer'
        class='custom-dropdown group hover:bg-blue-200 cursor-pointer bg-gray-200 w-full rounded px-2 py-3 flex items-center mb-2'
      >
        <option value='' disabled>Select an option...</option>
        <option
          v-for='(answer, answerIndex) in questions[currentQuestionIndex].answers'
          :key='answerIndex'
          :value='answer'
        >{{ answer.text }}</option>
      </select>
      <p class='text-gray-500 flex items-center p-0 text-right pt-2'>
        <i class='text-green-600 fas fa-shield-alt mr-1'></i>
        Safe, secure &amp; confidential
      </p>
    </div>
    <div v-else>
      <div v-if='loading' class='flex items-center'>
        <div class='spinner w-8 h-8'></div>
        <span class='ml-2'>Checking your property, please wait...</span>
      </div>
      <div v-else>
        <div class='text-md mb-4'>
          <div class='container mx-auto max-w-md'>
            <p class="pb-2">
              Great news! Based on our initial evaluation, we're interested in making a cash offer for your property. We understand this might be a difficult time for you, and we're here to help make things easier. 
              To proceed, we just need a few contact details from you. Our team is ready to support you every step of the way, ensuring a smooth and stress-free experience.
            </p>
          </div>
              <button id="free-cash-offer-button" class='bg-green-600 text-white font-bold py-3 px-4 rounded w-full shadow-md transform hover:shadow-lg transition duration-200'
              @click='submitQuiz()'>
              👉 Enter Contact Details
            </button>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.custom-dropdown {
  border: 1px solid black;
  appearance: none;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<script>
export default {
  name: "QuizFinancial",
  data() {
    return {
      questions: [
        {
          question: 'Are you the homeowner?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
          ],
        },
        {
          question: 'What type of property are you selling?',
          answers: [
            { text: 'Detached' },
            { text: 'Flat / Apartment' },
            { text: 'Semi-Detached' },
            { text: 'Mid-Terrece' },
            { text: 'End-Terrece' },
            { text: 'Bungalow' },
            { text: 'Land' },
            { text: 'Lower Cottage Flat' },
            { text: 'Upper Cottage Flat' },
            { text: 'Commercial Property' },
            { text: 'Other' },
          ],
        },
        {
          question: 'How many bedrooms?',
          answers: [
            { text: '1' },
            { text: '2' },
            { text: '3' },
            { text: '4' },
            { text: '5+' },
          ],
        },
        
        {
          question: 'Reason for sale?',
          answers: [
            { text: 'Stop Repossession' },
            { text: 'Stop Eviction' },
            { text: 'Mortgage Arrears' },
            { text: 'Other' },
          ],
        },
        {
          question: 'How fast do you need to sell?',
          answers: [
            { text: 'ASAP' },
            { text: 'Within 7 Days' },
            { text: '1 - 3 Weeks' },
            { text: '3 - 6 Months' },
            { text: '6 Months +' },
          ],
        },
        {
          question: 'What is the property condition?',
          answers: [
            { text: 'Needs TLC' },
            { text: 'Average' },
            { text: 'Good' },
          ],
        },
        {
          question: 'Are you currently on the market?',
          answers: [
            { text: 'Yes' },
            { text: 'No' },
          ],
        },
      ],
      currentQuestionIndex: 0,
      answers: [],
      selectedAnswer: '',
      loading: false,
    };
  },
  watch: {
    selectedAnswer(answer) {
      if (answer) {
        this.answers.push(answer);
        this.selectedAnswer = '';
        this.currentQuestionIndex++;
        
        if (this.currentQuestionIndex >= this.questions.length) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 3000);
        }
      }
    },
  },
  methods: {
    scrollToTop() {
      this.$refs.quizForm.scrollIntoView({ behavior: "smooth", block: "start", inline: "start", scrollBehavior: "auto" });
    },

    constructQuizData() {
      return {
        apiParam1: this.answers[0].text,
        apiParam2: this.answers[1].text,
        apiParam3: this.answers[2].text,
        apiParam4: this.answers[3].text,
        apiParam5: this.answers[4].text,
        apiParam6: this.answers[5].text,
        apiParam7: this.answers[6].text,
      };
    },
    submitQuiz() {
      const quizData = this.constructQuizData();
      this.$emit('completed', quizData);
    },
  },

  mounted() {
    this.scrollToTop();
  },
};
</script>

