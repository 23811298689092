import { createApp } from 'vue';
import App from './App.vue';
import './assets/css/styles.css';
import { createRouter, createWebHistory } from 'vue-router';
import AboutUs from './components/AboutUs.vue';
import HowWeWork from './components/HowWeWork.vue';
import ContactUs from './components/ContactUs.vue';
import ThankYou from './components/ThankYou.vue';
import EmptyContent from './components/EmptyContent.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import TermsOfUse from './components/Terms.vue';
import CookiesPolicy from './components/CookiesPolicy.vue';
import LanderPage from './components/LandingPage.vue';
import LanderPageV2 from './components/LandingPageV2.vue';
import ArticlePage from './components/ArticlePage.vue';
import NotFound from './components/404.vue';
import LandingFinancial from './components/LandingFinancial.vue';




const routes = [
  { path: '/', component: EmptyContent },
  { path: '/about-us', component: AboutUs },
  { path: '/how-we-work', component: HowWeWork },
  { path: '/contact-us', component: ContactUs },
  { path: '/thank-you', component: ThankYou },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/terms-of-use', component: TermsOfUse },
  { path: '/cookies-policy', component: CookiesPolicy }, // Added Cookies Policy
  { path: '/landerV1/:slug', component: LanderPage, name: 'lander' }, // Added LanderPage
  { path: '/landerV2/:slug', component: LanderPageV2, name: 'landerV2' }, // Added LanderPage
  { path: '/financial/:slug', component: LandingFinancial, name: 'financial' }, // Added LanderPage
  { path: '/articles/:slug', component: ArticlePage, name: 'article' }, // Added LanderPage
  { path: '/404', component: NotFound },
  { path: '/:catchAll(.*)', redirect: '/404' }, // Catch-all route to redirect to 404 page
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const pageTitle = to.matched.reduce((title, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      title = routeRecord.components.default.metaInfo().title;
    }
    return title;
  }, '');

  const metaDescription = to.matched.reduce((description, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      description = routeRecord.components.default.metaInfo().meta[0].content;
    }
    return description;
  }, '');

  const metaKeywords = to.matched.reduce((keywords, routeRecord) => {
    if (routeRecord.components.default.metaInfo) {
      keywords = routeRecord.components.default.metaInfo().meta[1].content;
    }
    return keywords;
  }, '');

  // Update the document title and meta tags
  document.title = pageTitle;
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="keywords"]').setAttribute('content', metaKeywords);

  // Manage the canonical link
  const existingCanonical = document.querySelector('link[rel="canonical"]');
  if (existingCanonical) {
    existingCanonical.parentNode.removeChild(existingCanonical);
  }

  const canonical = document.createElement('link');
  canonical.setAttribute('rel', 'canonical');
  canonical.setAttribute('href', window.location.origin + to.fullPath); // Use `to.fullPath` for the full path.
  document.head.appendChild(canonical);

  next();
});

createApp(App).use(router).mount('#app');


