<template>
  <header class="bg-white shadow-md py-3 relative z-50 px-1 md:px-0">
    <div class="container mx-auto flex justify-between items-center">
      <!-- Logo and Reviews Badge Container -->
      <div class="flex items-center"> <!-- Flex container for logo and reviews -->
        <!-- Logo -->
        <a href="/" class="mr-2"> <!-- Added margin-right to space out logo and badge -->
          <img src="@/assets/rapidsale-logo.svg" alt="Rapid Sale Logo" class="h-10 md:h-11">
        </a>
        <!-- Reviews Badge -->
        <a href="https://www.reviews.co.uk/company-reviews/store/rapid-sale" target="_blank">
          <img src="https://s3-eu-west-1.amazonaws.com/reviews-global/images/trust-badges/reviews-trust-logo-3.png" alt="Trust Badge" class="h-11 md:h-10">
        </a>
      </div>

  
      <nav class="hidden md:flex items-center space-x-6 md:relative md:bg-transparent md:shadow-none" v-show="(!isMobile || menuOpen) && !$route.path.startsWith('/articles/')">
        <a href="/" class="text-gray-800 hover:text-blue-600">HOME</a>
        <a href="/how-we-work" class="text-gray-800 hover:text-blue-600">HOW WE WORK</a>
        <router-link to="/about-us" class="text-gray-800 hover:text-blue-600">ABOUT US</router-link>
        <router-link to="/contact-us" class="text-gray-800 hover:text-blue-600">CONTACT US</router-link>
      </nav>


      <div @click.prevent="toggleMenu" class="md:hidden focus:outline-none cursor-pointer" v-if="!$route.path.startsWith('/articles/')">
        <i v-if="!menuOpen" class="fas fa-bars text-xl text-gray-800"></i>
        <i v-else class="fas fa-times text-xl text-gray-800"></i>
      </div>

      <!-- Phone Number and Icon Container -->
      <div class="flex items-center order-3 ml-4">
        <!-- Phone Icon and Number Link -->
        <a href="tel:03300228803" class="bg-green-500 text-white px-3 py-1 rounded-md flex items-center">
          <!-- Heroicon for mobile phone, always visible -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6 mr-2" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" clip-rule="evenodd" />
          </svg>
          <!-- Phone Number - Hidden on Small Screens, Visible on Medium and Larger Screens -->
          <div class="hidden md:flex flex-col">
            <div class="font-semibold">0330 022 8803</div>
            <div class="text-xs">24/7 Mobile Friendly</div>
          </div>
        </a>
      </div>
    </div>


    <div class="mobile-menu md:hidden" v-show="menuOpen">
      <nav class="bg-white shadow-md py-3 mt-1">
        <div class="container mx-auto flex flex-col space-y-3">
          <a href="/" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">HOME</a>
          <a href="/how-we-work" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">HOW WE WORK</a>
          <a href="/about-us" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">ABOUT US</a>
          <a href="/contact-us" class="block py-2 px-4 text-gray-800 hover:text-blue-600 hover:bg-gray-100 rounded">CONTACT US</a>
        </div>
      </nav>
    </div>
  </header>


  <div class="bg-black pb-1 pt-1">
      <div class="reviews-io-rating-bar-widget"
          widget-id="5NAj1ofx78DZ3WUS"
          data-store-name="rapid-sale"
          lang="en">
      </div>
  </div>


</template>


<script>
export default {
  name: "AppHeader",
  data() {
    return {
      menuOpen: false,
      isMobile: false,
    };
  },
  methods: {
    toggleMenu() {
      if (this.isMobile) {
        this.menuOpen = !this.menuOpen;
      }
    },
    loadReviewWidgetScript() {
      const script = document.createElement('script');
      script.src = '//widget.reviews.io/modern-widgets/rating-bar.js';
      script.async = true;
      document.body.appendChild(script);
    }
  },
  mounted() {
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 768;
    });

    // Call the function to load the Reviews.io widget script
    this.loadReviewWidgetScript();
  },
};
</script>
