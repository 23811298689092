<template>
  <div></div>
</template>

<script>
export default {
  name: 'EmptyContent',
  metaInfo() {
    return {
      title: 'Fast Cash Offer - Rapid Sale',
      meta: [
        {
          name: 'description',
          content: 'Rapid Sale specialise in purchasing property for cash, fast with 100% No Fees. Get a Free Cash Offer Today.',
        },
        {
          name: 'keywords',
          content: 'property cash offer, we buy any home, fast house sale, cash house buyers, sell house fast',
        },
      ],
    };
  },
};
</script>
