<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <img class="h-full w-full object-cover opacity-50" src="@/assets/sold-cash-request.jpg" alt="Sold">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl">Thank You for Choosing Rapid Sale</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">We appreciate the opportunity to assist you with your property needs. Rest assured, our team is already processing your request and will be in touch soon.</p>
      
      <h2 class="text-2xl text-white mt-4">Our Commitment to Excellence</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">Trust and reliability are the cornerstones of our service. We encourage you to read through our client reviews to understand our dedication to customer satisfaction.</p>

      <!-- Reviews Section -->
      <div class="text-center mt-8">
        <p class="text-sm text-gray-500">Click to read all our reviews:</p>
        <a target="_blank" href="https://www.reviews.co.uk/company-reviews/store/rapid-sale">
          <img id="float-left-widget-img-preview" src="https://media.reviews.co.uk/badge/rapid-sale.png?float=left" class="mx-auto mt-2">
        </a>
      </div>

      <div class="mt-6 max-w-3xl text-xl text-indigo-100">
        <p><strong>David Lisle (Verified Customer):</strong> "Professional, informative, and reassuring. Claire was outstanding."</p>
        <p><strong>Glen Cummings (Verified Customer):</strong> "Responsive, friendly, and fair. Highly recommend Rapid Sale."</p>
        <!-- Additional reviews can be added here -->
      </div>

      <h2 class="text-2xl text-white mt-4">The Rapid Sale Difference</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">Our approach is straightforward and honest. With Rapid Sale, you get a direct, cash offer with no hidden fees or last-minute price drops.</p>

      <h2 class="text-2xl text-white mt-4">Transparent and Supportive</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">We aim to make the selling process as stress-free as possible, understanding the urgency and sensitivity that can accompany property sales.</p>

      <!-- Missing Content -->
      <h2 class="text-2xl text-white mt-4">No Hidden Fees, No Surprises</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">Beware of companies that charge upfront fees for a cash offer. At Rapid Sale, we assure you there are absolutely NO fees at any stage of our process. The amount we offer is the full amount you'll receive in your bank account.</p>

      <h2 class="text-2xl text-white mt-4">Quick, Efficient, and Empathetic</h2>
      <p class="mt-4 max-w-3xl text-xl text-indigo-100">Not only do we offer cash for your property within 7 days (sometimes even quicker), but we also provide the unique opportunity to stay in the property for up to 3 months rent-free post-sale, depending on the area.</p>
      
      <!-- Contact Options -->
      <div class="flex flex-col mt-8 text-left">
        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <a href="https://api.whatsapp.com/send?phone=447797796289" target="_blank">
            <i class="fab fa-whatsapp mr-2"></i> WhatsApp
          </a>
        </div>
        <div class="text-xl text-white mb-6">
          Click to message us on WhatsApp
        </div>

        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-phone mr-2"></i> Phone
        </div>
        <div class="text-xl text-white mb-6">
          0330 022 8803
        </div>

        <div class="text-2xl font-semibold text-indigo-200 mb-2">
          <i class="fas fa-envelope mr-2"></i> Email
        </div>
        <div class="text-xl text-white mb-6">
          hello@rapidsale.co.uk
        </div>
      </div>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">Looking forward to assisting you further. Warm regards, Adam and the Rapid Sale Team.</p>
    </div>
  </div>
</template>




<style>
.opacity-50 {
    opacity: 0.4;
}
</style>
    
    <script>
    export default {
      name: 'ThankYou',
      
      metaInfo() {
        return {
          title: 'Thank You - Rapid Sale',
          meta: [
            {
              name: 'description',
              content: 'Thank you for submitting your property details for a free cash offer!',
            },
            {
              name: 'keywords',
              content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
            },
          ],
          link: [
        { rel: 'canonical', href: 'https://rapidsale.co.uk/thank-you' }
        ]
        };
      },
    };
    </script>
  
    