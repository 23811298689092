<template>

<div class="relative">
  <div class="absolute inset-0">
    <img class="h-full w-full object-cover" src="@/assets/rapid-staff-image2.jpg" alt="Rapid Sale Uk Team">
    <div class="absolute inset-0 mix-blend-multiply" aria-hidden="true"></div>
  </div>
  <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
    <!-- Add the black box styling here -->
    <div class="bg-black bg-opacity-82 rounded-lg p-6">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-4xl pb-2">About Us</h1>

      <h2 class="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl font-bold tracking-tight text-white">The UK's Premier Property Cash Buyers</h2>


      <p class="mt-6 max-w-3xl text-xl text-white">At Rapid Sale, your peace of mind is our top priority. As seasoned experts in the UK property market, we're committed to offering a service that's not just professional and discreet, but also friendly and understanding.</p>
      <p class="mt-6 max-w-3xl text-xl text-white">In our journey, we've been dedicated to delivering tailored solutions to homeowners across the UK. Facing urgent financial demands, coping with eviction notices, or navigating suspended repossession orders? We've managed it all. Our experience even extends to critical scenarios like bailiffs at your doorstep. Beyond the pressing situations, we're also here for those who simply prefer a swift sale to bypass the complexities of the open market – ensuring a seamless process. Our commitment to excellence is reflected in the positive feedback from our clients.</p>
      <p class="mt-6 max-w-3xl text-xl text-white">Every property story is unique, and we honor that by going above and beyond. Need help with removals or storage? Seeking advice on the next steps? We're here every step of the way, ensuring your property transition is as stress-free as possible. At Rapid Sale, we don't just handle transactions; we transform challenging moves into positive new beginnings.</p>
      
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="$router.push('/')">
        Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
</div>

</template>

<style>
.opacity-50 {
    opacity: 0.3;
}
</style>
  
<script>

export default {
  name: 'AboutUs',
  metaInfo() {
    return {
      title: 'About Us | Rapid Sale',
      meta: [
        {
          name: 'description',
          content: 'Welcome to Rapid Sale the UK\'s Premier Property Cash Buyers',
        },
        {
          name: 'keywords',
          content: 'rapid sale, rapidsale, property cash offer, we buy any home, fast house sale, cash house buyers',
        },
      ],
      link: [
        { rel: 'canonical', href: 'https://rapidsale.co.uk/about-us' }
      ]
    };
  },
};
</script>

  

  