<template>
  <div class="relative bg-indigo-800">
    <div class="absolute inset-0">
      <div class="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true"></div>
    </div>
    <div class="relative mx-auto max-w-3xl px-6 py-16 sm:py-24 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Terms of Use</h1>
      <p class="mt-6 max-w-3xl text-xl text-indigo-100">Welcome to Rapid Sale, a trading name of IAAW Limited, registered in Jersey (Registration number: 121664, ICO: ZB523327). By using our website, you agree to comply with and be bound by these Terms of Use and our Privacy Policy, which govern our relationship with you in relation to this site. If you do not agree with any part of these terms, please do not use our website.</p>

      <p class="mt-6 max-w-3xl text-xl text-indigo-100">The terms 'Rapid Sale'/ 'We'/ 'Us' refer to IAAW Limited, operating under the trading name Rapid Sale. 'You' refers to the user or viewer of our website.</p>

      <ol class="list-decimal list-inside space-y-2 py-5 pl-5">
          <li class="max-w-3xl text-xm text-indigo-100">
            The content on our website is for your general information and use only, subject to change without notice.
          </li>
          <li class="max-w-3xl text-xm text-indigo-100">
            We do not guarantee the accuracy, timeliness, performance, completeness, or suitability of information and materials found here for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors to the fullest extent permitted by law.
          </li>
          <li class="max-w-3xl text-xm text-indigo-100">
            Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It is your responsibility to ensure that products, services, or information available through this website meet your specific requirements.
          </li>
          <li class="max-w-3xl text-xm text-indigo-100">
            This website contains material owned by or licensed to us, including design, layout, look, appearance, and graphics. Reproduction is prohibited except in accordance with our copyright notice.
          </li>
          <li class="max-w-3xl text-xm text-indigo-100">
            All trademarks reproduced on this website, not owned by, or licensed to the operator, are acknowledged.
          </li>
          <li class="max-w-3xl text-xm text-indigo-100">
            Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.
          </li>
          <li class="max-w-3xl text-xm text-indigo-100">
            We may include links to other websites for further information. These links do not signify our endorsement, and we bear no responsibility for the content of linked websites.
          </li>
          <li class="max-w-3xl text-xm text-indigo-100">
            Your use of this website and any dispute arising out of such use is subject to the laws of England, Northern Ireland, Scotland, Wales, and Jersey.
          </li>
      </ol>
    </div>
  </div>
</template>

  
  <style>
  .opacity-50 {
      opacity: 0.4;
  }
  </style>
      
      <script>
      export default {
        name: 'TermsOfUse',
        metaInfo() {
          return {
            title: 'Terms of Use - Rapid Sale',
            meta: [
              {
                name: 'description',
                content: 'Terms of Use',
              },
              {
                name: 'keywords',
                content: 'property cash offer, we buy any home, fast house sale, cash house buyers, thank you',
              },
            ],
            link: [
            { rel: 'canonical', href: 'https://rapidsale.co.uk/terms-of-use' }
          ]
          };
        },
      };
      </script>
    
      