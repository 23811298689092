<template>

  <div class="header-container flex flex-col items-center bg-cover bg-center relative" 
      :style="`background-image: url(${headerImageUrl})`"
      >
      <div class="overlay absolute inset-0 bg-blue-900 opacity-90"></div>      
        <div class="flex flex-col items-center z-10" :class="{ 'hidden': showQuiz || showContact }">
          <div class="key-points-container text-left w-full px-4 sm:w-1/2 sm:px-0 sm:text-center pt-0 sm:pt-20 md:pt-10">
            <h1 class="text-xl md:text-3xl font-extrabold text-white text-center pt-7 sm:text-center px-2 tracking-wider">{{ lander.Title }}</h1>
            <ul class="mt-4 flex space-x-0 sm:space-x-4 md:space-x-6 justify-start sm:justify-center flex-wrap text-left text-white">
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> Quick sale process
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> Get Cash in 7 days or sooner
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> Best prices paid
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> No viewings or sale boards
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> Up to 3 months rent-free stay
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> No last minute price drops
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> We use our own company funds
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> We buy regardless of condition
              </li>
              <li class="flex items-center mb-2 px-2 sm:px-0 pr-6">
                <i class="fas fa-check text-green-500 mr-2"></i> A guaranteed sale
              </li>
            </ul>
            
          </div>
         <AddressForm @addressSelected="onAddressSelected" class="w-full px-4 sm:w-1/2 sm:px-0 mt-4" />
  
  
                  <div class="mt-4 small-device-text">
                  <div class="w-full sm:max-w-sm mx-auto text-sm text-gray-300 pb-10">
                      <p class="text-xs font-bold mb-2">
                  <span class="text-red-500 mr-1">&#9888;</span>
                  Our offers are competitive, and there's no obligation to accept. You have nothing to lose.
                  </p>
            </div>
          </div>
        </div>
  
        <div class="flex flex-col items-center pt-10 mx-4 justify-start z-10" :class="{ 'hidden': !showQuiz }">
          <div class="text-3xl font-bold text-white mb-8">
            Quick Property Check
          </div>
  
          <QuizFinancial @completed="onQuizComplete" />
          <div class="sm:max-w-sm mx-auto text-sm text-white pt-5 pb-10" style="font-size: 10px;">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6 inline-block mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>
            This information helps us tailor our offer to meet your specific needs.
        </div>
  
  
        </div>
  
        <div class="flex flex-col items-center pt-10 justify-start z-10 pb-10" :class="{ 'hidden': !showContact }">
          <ContactFinancialForm :address-data="addressData" 
                        :quiz-data="quizData"
                        :lp-offer-id="'6'"
                        :lp-campaign-id="'5e2bf947eb5c7'"
                        :gclid="gclid" 
                        :utmCampaign="utmCampaign" 
                        :utmSource="utmSource" 
                        :utmMedium="utmMedium"
                        :utmKeyword="utmKeyword"
                        :utmMatch="utmMatch" 
                        :utmDevice="utmDevice" />
        </div>
      </div>
  
      <div class="review-widget-container bg-gray-100 py-4">
        <div id="reviewsio-carousel-widget"></div>
      </div>
  
     <!-- Review Widget Container -->
     <div class="review-widget-container bg-gray-100 py-7" v-if="!$route.path.startsWith('/landerv1/') && !$route.path.startsWith('/landerv2/')">
        <!-- <div class="review-cards-container bg-gray-100 py-4"> -->
        <div class="max-w-4xl mx-auto">
          <h2 class="text-4xl font-semibold text-blue-600 mb-6 text-center">Our Reviews</h2>
          <p class="text-center text-lg text-gray-700 mb-8">We have over 135 - 5 Star Reviews from our trusted 3rd party review collector.</p>
          <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div v-for="review in reviews" :key="review.id" class="review-card bg-white p-4 rounded-lg shadow-lg">
              <div class="flex justify-center mb-4">
                <img src="@/assets/reviewsio-logo.png" alt="Reviews.io Logo" class="logo">
              </div>
              <div class="flex items-center mb-2">
                <div class="text-lg font-bold">{{ review.name }}</div>
              </div>
              <div class="flex items-center mb-2">
                <div class="text-yellow-500">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
              <div class="text-gray-700">{{ review.text }}</div>
            </div>
          </div>
        </div>
      </div>
  
  
    <!---</div>-->
  
  
      <div class="guaranteed-offer p-6">
    <h2 class="text-3xl font-semibold text-blue-600 mb-6 text-center">Want a Guaranteed Offer?</h2>
    <p class="text-lg px-4 mb-6 text-gray-700">
      With no fees, complete flexibility on your moving date, 100% confidentiality, no stress, and no estate agents! We provide speed, certainty, simplicity.
    </p>
    <div class="offer-steps grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
      <div class="step">
        <i class="fas fa-comments chat-bubble-icon text-3xl text-green-500"></i>
        <p class="mt-2">Tell us about your property</p>
      </div>
      <div class="step">
        <i class="fas fa-handshake cash-offer-icon text-3xl text-green-500"></i>
        <p class="mt-2">Accept our cash offer</p>
      </div>
      <div class="step">
        <i class="fas fa-university bank-icon text-3xl text-green-500"></i>
        <p class="mt-2">Cash in the bank in 7 days</p>
      </div>
    </div>
    <p class="text-lg px-4 mt-6 mb-8 text-gray-700">
      Our service is simple, stress-free, giving you peace of mind.
    </p>
    <button class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg text-xl mx-auto block transition duration-300 ease-in-out" @click="goToTop">
      Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
    </button>
  </div>
  
  
  
  <div class="bg-blue-800 full-width flex justify-center items-center text-white p-4">
    <div class="max-w-3xl p-3">
      <h2 class="text-2xl font-bold mb-8 text-center">{{ lander.TitleSub }}</h2>
    
      <div class="text-lg" v-html="lander.Content" v-if="!error"></div>
      <button class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg mt-6 text-xl mx-auto block" @click="goToTop">
          Get a Free Cash Offer <i class="fas fa-arrow-right ml-2"></i>
      </button>
    </div>
  </div>
  
  
  </template>
  
  
  
  
  <script>
  import axios from 'axios';
  //import CountdownTimer from "@/components/CountdownTimer.vue";
  import AddressForm from "@/components/AddressForm.vue";
  import QuizFinancial from "@/components/QuizFinancial.vue";
  import ContactFinancialForm from "@/components/ContactFinancialForm.vue";
  import KeyImage from '@/assets/key-image.png'; // This should be the path to your image
  
  
  export default {
    components: {
      //CountdownTimer,
      AddressForm,
      QuizFinancial,
      ContactFinancialForm,
    },
    props: {
      gclid: String,      // Define gclid as a prop
      utmMatch: String,   // Define utmMatch as a prop
      // ... other props ...
    },
    data() {
      return {
        lander: {},
        error: false,
        keyImage: KeyImage,
        addressData: {},
        quizData: {},
        showQuiz: false,
        showAddressForm: true,
        showContact: false,
        utmCampaign: '',
        utmSource: '',
        utmMedium: '',
        utmKeyword: '',
        utmDevice: '',
        utmGclid: '',
        headerImageUrl: require("@/assets/houses-bg.jpg"),
        reviews: [
        { id: 1, name: "Lucinda Fyfield", text: "I cannot fault the help from Adam and his team. Communication has been great from start to finish. In a time that was stressful Adam has alleviated the stress. I would highly recommend using this company and will recommend to those who may need them in the future." },
        { id: 2, name: "David Lisle", text: "Would fully recommend this company. Professional all the way through kept fully informed. Claire was excellent, answered all my calls quickly, reassured me throughout and gave me all the information and guidance I needed to help me through. Absolute first class." },
        { id: 3, name: "Mark Simkins", text: "Wanted to dispose of a house without hassle, Adam delivered! Couple of phone calls and house bought for cash. Sale completed in just over a week, minimal effort required from me!" }
      ]
      };
    },
    async mounted() {
      try {
        await this.fetchLanderData();
        this.updateMetaInfo();
      } catch (error) {
        console.error(error);
        this.error = true;
        this.redirectTo404();
      }
    },
    methods: {
      goToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      async fetchLanderData() {
        const response = await axios.get(`https://blog.asaphousebuyers.co.uk/api/landers?filters[Domain][$eq]=rapidsale.co.uk&filters[slug][$eq]=${this.$route.params.slug}`);
        const landerData = response.data.data.find(item => item.attributes.slug === this.$route.params.slug);
        if (landerData) {
          this.lander = landerData.attributes; // Using the attributes from the matched item
        } else {
          throw new Error('No matching lander found');
        }
      },
      
      updateMetaInfo() {
        if (this.error) {
          // Handle error case
          document.title = 'Error | Rapid Sale UK';
          const errorDescription = 'An error occurred while fetching data.';
          const metaTags = [
            { name: 'description', content: errorDescription },
          ];
          this.setMetaTags(metaTags);
          return;
        }
  
        const title = this.lander.seoTitle || 'Get Cash for Your House in Just 7 Days';
        const description = this.lander.seoDescription || 'Are you looking to sell your house fast? We buy houses for cash, no matter the condition. We offer a quick and easy process, with no fees or commissions. Get cash for your house in just 7 days!';
        const keywords = this.lander.Keywords || 'Sell my house fast, We buy houses for cash, Get cash for your house, Sell your house fast and for top dollar, We buy houses in any condition';
  
        const metaTags = [
          { name: 'description', content: description },
          { name: 'keywords', content: keywords },
        ];
  
        document.title = title;
        this.setMetaTags(metaTags);
      },
      onAddressSelected(address) {
        this.addressData = address;
        this.showQuiz = true;
        this.showAddressForm = false;
      },
      onQuizComplete(quizResults) {
        this.quizData = quizResults;
        this.showQuiz = false;
        this.showContact = true;
      },
      setMetaTags(tags) {
        tags.forEach(tag => {
          const existingTag = document.head.querySelector(`meta[name="${tag.name}"]`);
          if (existingTag) {
            existingTag.setAttribute('content', tag.content);
          } else {
            const newTag = document.createElement('meta');
            newTag.setAttribute('name', tag.name);
            newTag.setAttribute('content', tag.content);
            document.head.appendChild(newTag);
          }
        });
      },
      redirectTo404() {
        this.$router.replace('/404'); // Replace with your actual 404 page route
      },
    },
  };
  </script>
  
  
  
  
  