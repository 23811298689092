<template>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto p-6 lg:p-8 bg-gray-100">
    <div class="md:col-span-2 prose lg:prose-xl bg-white p-8 rounded">
      <p class="mb-4 text-sm text-gray-500">
        By <span class="font-bold">Rapid Sale - Published on: {{ formatDate(newsArticles.createdAt) }}</span>
      </p>
      <h1 class="text-2xl md:text-4xl mb-4 font-extrabold text-black">{{ newsArticles.Title }}</h1>
            <ul class="list-none pl-0 mb-4 md:pl-4 md:mb-6">
                <li class="mb-2 md:mb-4 flex items-center">
                    <i class="fas fa-check text-green-500 mr-2 md:text-lg"></i>
                    <p class="text-sm md:text-lg text-gray-800" v-html="newsArticles.Tick1" v-if="!error"></p>
                </li>
                <li class="flex items-center">
                    <i class="fas fa-check text-green-500 mr-2 md:text-lg"></i>
                    <p class="text-sm md:text-lg text-gray-800" v-html="newsArticles.Tick2" v-if="!error"></p>
                </li>
            </ul>



      <div class="text-lg" v-html="newsArticles.Content" v-if="!error"></div>
      <ArticleList />

    </div>
      <div class="md:col-span-1 prose lg:prose-xl p-6 bg-white shadow-lg border-2 border-blue-500 rounded-md">
      <h2 class="text-2xl mb-2 text-black">How Fast Do You Need to Sell?</h2>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2 bg-gray-200 p-4 rounded-md">
              <button @click="goToHome" class="px-4 py-2 text-lg bg-green-500 hover:bg-green-600 text-white rounded shadow transition-colors">ASAP</button>
              <button @click="goToHome" class="px-4 py-2 text-lg bg-green-500 hover:bg-green-600 text-white rounded shadow transition-colors">Within 7 Days</button>
              <button @click="goToHome" class="px-4 py-2 text-lg bg-green-500 hover:bg-green-600 text-white rounded shadow transition-colors">1 - 4 Weeks</button>
              <button @click="goToHome" class="px-4 py-2 text-lg bg-green-500 hover:bg-green-600 text-white rounded shadow transition-colors">5 Weeks Plus</button>
          </div>
      </div>
  </div>

</template>

<script>
import axios from 'axios';
import ArticleList from './ArticleList.vue';

export default {
  components: {
    ArticleList,
  },
  data() {
    return {
      newsArticles: {},
      error: false,
    };
  },
  async mounted() {
    try {
      await this.fetchNewsData();
      this.setMetaTags();
    } catch (error) {
      console.error(error);
      this.error = true;
      this.redirectTo404();
      console.log("Fetched News Articles: ", this.newsArticles);
    }
  },
  methods: {
    async fetchNewsData() {
      const response = await axios.get(
        `https://blog.asaphousebuyers.co.uk/api/news-articles?filters[Domain][$eq]=rapidsale.co.uk&filters[slug][$eq]=${this.$route.params.slug}`
      );

      const newsData = response.data.data.find(
        (article) => article.attributes.slug === this.$route.params.slug
      );

      if (newsData) {
        this.newsArticles = newsData.attributes;
      } else {
        throw new Error('No news articles found');
      }
    },
    formatDate(date) {
    return new Date(date).toLocaleDateString('en-GB');
  },
    setMetaTags() {
      if (this.error) {
        // Handle error case
        document.title = 'Error | Rapid Sale';
        const errorDescription = 'An error occurred while fetching data.';
        const metaTags = [
          { name: 'description', content: errorDescription },
        ];
        this.updateHead(metaTags);
        return;
      }

      const title = this.newsArticles.seoTitle || 'Get Cash for Your House in Just 7 Days';
      const description =
        this.newsArticles.seoDescription ||
        'Are you looking to sell your house fast? We buy houses for cash, no matter the condition. We offer a quick and easy process, with no fees or commissions. Get cash for your house in just 7 days!';
      const keywords =
        this.newsArticles.keywords ||
        'Sell my house fast, We buy houses for cash, Get cash for your house, Sell your house fast and for top dollar, We buy houses in any condition';

      const metaTags = [
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
      ];

      document.title = title;
      this.updateHead(metaTags);
    },
    updateHead(tags) {
      const head = document.head;

      // Remove existing meta tags
      const existingMetaTags = head.querySelectorAll('meta[name]');
      existingMetaTags.forEach((tag) => head.removeChild(tag));

      // Create and append new meta tags
      tags.forEach((tag) => {
        const newTag = document.createElement('meta');
        newTag.setAttribute('name', tag.name);
        newTag.setAttribute('content', tag.content);
        head.appendChild(newTag);
      });
    },
    redirectTo404() {
      this.$router.replace('/404');
    },
    goToHome() {
      this.$router.push('/#top');
    },
  },
};
</script>