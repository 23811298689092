<template>
  <div class="more-articles">
    <h2 class="text-2xl font-bold mb-4">Featured Articles</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div v-for="article in paginatedArticles" :key="article.id" class="rounded-lg overflow-hidden shadow-md">
        <img :src="getImageSrc(article.Content)" alt="Article thumbnail" class="w-full h-40 object-cover">
        <div class="p-4 bg-white">
          <span class="text-sm text-gray-500">
            {{ article.createdAt ? formatDate(article.createdAt) : 'N/A' }}
          </span>
          <h3 class="text-lg font-bold mb-2 article-title">{{ article.Title }}</h3>
          <a :href="`/articles/${article.slug}`" class="text-green-500 hover:text-green-600">Read More &#8594;</a>
        </div>
      </div>
    </div>
    <!-- Pagination Controls -->
<!-- Pagination Controls -->
<div class="flex justify-center mt-4 space-x-1">
  <button
    @click="prevPage"
    :disabled="currentPage === 1"
    class="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-blue-200"
  >
    Previous
  </button>
  <button
    v-for="page in totalPages"
    :key="page"
    @click="setPage(page)"
    :class="{'bg-blue-500 hover:bg-blue-600 text-white': currentPage === page, 'bg-white hover:bg-blue-100 text-blue-500': currentPage !== page}"
    class="px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
  >
    {{ page }}
  </button>
  <button
    @click="nextPage"
    :disabled="currentPage === totalPages"
    class="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-blue-200"
  >
    Next
  </button>
</div>

  </div>
</template>

<style>
.article-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      articles: [],
      currentPage: 1,
      articlesPerPage: 6,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.articles.length / this.articlesPerPage);
    },
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.articlesPerPage;
      const end = start + this.articlesPerPage;
      return this.articles.slice(start, end);
    },
  },
  methods: {
    getImageSrc(htmlString) {
      const parser = new DOMParser();
      const html = parser.parseFromString(htmlString, 'text/html');
      const img = html.querySelector('img');
      return img ? img.src : '';
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-GB');
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setPage(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  async mounted() {
    const response = await axios.get('https://blog.asaphousebuyers.co.uk/api/news-articles?filters[Domain][$eq]=rapidsale.co.uk');
    this.articles = response.data.data
      .map((article) => article.attributes)
      .sort((a, b) => {
        const dateA = new Date(a.publishedAt);
        const dateB = new Date(b.publishedAt);
        return dateB - dateA || new Date(b.createdAt) - new Date(a.createdAt);
      });
  },
};
</script>
